import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { themeEnterprise } from '../themes'
import { Icon } from './Icon'
const styles = themeEnterprise

const Component = styled.footer`
  font-family: ${styles.fonts.primary} !important;
  background-color: ${styles.color.grey};
  box-shadow: 0 -1px 0 0 #d5d6d8;
  min-height: 151px;
  width: 100%;
  display: flex;

  & .-container {
    margin: 0 auto;
    padding: calc(${styles.baseUnit} * 6) calc(${styles.baseUnit} * 4);
  }
  & .-navActions {
    display: flex;
    justify-content: center;
  }

  & .-navAction {
    color: ${styles.color.greyShade4};
    font-size: 13px;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: calc(${styles.baseUnit} * 5);
    }

    &:hover {
      color: #008d99;
    }
  }

  & .-socialNetworks {
    display: flex;
    justify-content: center;
    margin-top: calc(${styles.baseUnit} * 8);
  }

  & .-socialNetwork {
    margin-right: calc(${styles.baseUnit} * 2);
  }

  & .-socialNetwork::before {
    color: ${styles.color.greyShade1};
    background-color: #939aad;
    border: 1px solid ${styles.color.greyShade1};
    border-radius: 50%;
    font-size: 48px;
    line-height: 1;
  }

  & .-socialNetwork:hover::before {
    border: 1px solid #008d99;
  }

  /* responsive styles */
  @media screen and ${styles.small} {
    & .-container {
      width: 100%;
    }

    & .-navActions {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    & .-navAction {
      min-width: 30%;
      text-align: center;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`

export const NavFooter = ({ links }) => (
  <Component>
    <div className="-container">
      <div className="-navActions">
        {links.map((link, key) => (
          <a key={key} className="-navAction" href={link.href}>
            {link.copy}
          </a>
        ))}
      </div>
      <div className="-socialNetworks">
        <a href="https://www.facebook.com/Foodsby/">
          <Icon className="-socialNetwork" variant="facebook" />
        </a>
        <a href="http://twitter.com/foodsby">
          <Icon className="-socialNetwork" variant="twitter" />
        </a>
        <a href="https://www.instagram.com/foodsbyinc/">
          <Icon className="-socialNetwork" variant="instagram" />
        </a>
        <a href="https://www.linkedin.com/company/foodsby-llc">
          <Icon className="-socialNetwork" variant="linkedin" />
        </a>
      </div>
    </div>
  </Component>
)

NavFooter.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object)
}
export default NavFooter
